<template>
	<div>
		<v-tooltip top>
			<template #activator="{ on, attrs }">
				<v-btn
					fab
					color="error"
					elevation="0"
					v-bind="attrs"
					:disabled="disableHangUp"
					:small="!isOnCall"
					v-on="on"
					@click="isOnCall ? handleHangup() : handleReject()"
				>
					<v-icon>mdi-phone-hangup</v-icon>
				</v-btn>
			</template>
			<span>{{ $t(isOnCall ? "hangup-cta" : "ignore") }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import { mapGetters } from "vuex";

export default {

	name: "HangUpButton",

	computed: {
		...mapGetters("voice", ["isOnCall", "activeCall"]),

		disableHangUp() {
			return this.activeCall.onHold;
		}
	},

	methods :{
		async handleHangup() {
			this.activeCall?.hangUp();
			this.$store.commit("voice/clearDigitsPressed");
			if (window.mediaStream) {
				window.mediaStream.getTracks().forEach((tracks => {
					tracks.stop();
				}));
			}
		},

		handleReject() {
			// Warning: This action reject all incoming user group call too
			this.activeCall?.rejectAll();
		}
	}

};

</script>