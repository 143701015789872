var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-sheet",
    { attrs: { width: "100%" } },
    [
      _c("span", { staticClass: "text-body-1 px-2" }, [
        _vm._v(_vm._s(_vm.$t("support-instruction"))),
      ]),
      _c(
        "v-form",
        {
          staticClass: "text-center my-5",
          model: {
            value: _vm.validForm,
            callback: function ($$v) {
              _vm.validForm = $$v
            },
            expression: "validForm",
          },
        },
        [
          _c(
            "v-row",
            { staticClass: "px-5 flex-column" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pr-11", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-5",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("last-name"),
                          rules: [_vm.rules.required(_vm.form.name, "name")],
                          "background-color": "light-primary",
                        },
                        model: {
                          value: _vm.inputForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputForm, "name", $$v)
                          },
                          expression: "inputForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticClass: "pr-11", attrs: { cols: "6" } },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-5",
                        attrs: {
                          outlined: "",
                          dense: "",
                          label: _vm.$t("email"),
                          rules: [
                            _vm.rules.required(_vm.form.email, "email"),
                            _vm.rules.email,
                          ],
                          "background-color": "light-primary",
                        },
                        model: {
                          value: _vm.inputForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.inputForm, "email", $$v)
                          },
                          expression: "inputForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-n7" },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                staticClass: "mt-5",
                                attrs: {
                                  outlined: "",
                                  dense: "",
                                  label: _vm.$t("url"),
                                  "background-color": "light-primary",
                                },
                                model: {
                                  value: _vm.inputForm.url,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.inputForm, "url", $$v)
                                  },
                                  expression: "inputForm.url",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mb-1 ml-2",
                                              attrs: { color: "primary" },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("url-tooltip"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { attrs: { cols: "6" } },
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c("v-file-input", {
                            staticClass: "mt-5",
                            attrs: {
                              outlined: "",
                              "prepend-icon": "",
                              "append-icon": "mdi-paperclip",
                              dense: "",
                              label: _vm.$t("screenshot"),
                              "background-color": "light-primary",
                              accept: "image/png, image/jpeg, image/svg+xml",
                              clearable: "",
                            },
                            model: {
                              value: _vm.imgFileInput,
                              callback: function ($$v) {
                                _vm.imgFileInput = $$v
                              },
                              expression: "imgFileInput",
                            },
                          }),
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "v-icon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "mb-1 ml-2",
                                              attrs: { color: "primary" },
                                            },
                                            "v-icon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [_vm._v(" mdi-information-outline ")]
                                      ),
                                    ]
                                  },
                                },
                              ]),
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.$t("screenshot-tooltip"))),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mt-n2", attrs: { "no-gutters": "" } },
                [
                  _c("v-textarea", {
                    staticClass: "mt-2",
                    attrs: {
                      outlined: "",
                      hint: _vm.$t("problem-info"),
                      label: _vm.$t("problem-description"),
                      rules: [
                        _vm.rules.required(_vm.form.description, "description"),
                      ],
                      "background-color": "light-primary",
                    },
                    model: {
                      value: _vm.inputForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.inputForm, "description", $$v)
                      },
                      expression: "inputForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }