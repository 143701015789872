var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: {
        width: _vm.$vuetify.breakpoint.xl ? "60%" : "70%",
        title: _vm.$t("contact-us"),
        type: "question",
        "is-cta-disable": _vm.isCtaDisable,
        "cancel-text": _vm.$t("cancel"),
        "confirm-text": _vm.$t("send"),
      },
      on: { cancel: _vm.closeDialog, confirm: _vm.sendMessage },
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _c(
        "v-row",
        { staticClass: "px-2", attrs: { "no-gutters": "" } },
        [
          _c("support-form", {
            attrs: { form: _vm.form, "support-dialog": _vm.supportDialog },
            on: {
              updateValidation: _vm.updateValidation,
              onDialogClose: function ($event) {
                _vm.supportDialog = false
              },
            },
            model: {
              value: _vm.validForm,
              callback: function ($$v) {
                _vm.validForm = $$v
              },
              expression: "validForm",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }