import PhoneCall from "./phone-call";
import axios from "axios";

class OutboundCall extends PhoneCall {
	constructor(callInstance, parameters) {
		super(callInstance, false, parameters);
		this.direction = "outbound";
	}

	start = () => {
		const durationInt = setInterval(() => {
			const status = this.callInstance?.status();

			if (status === "ringing" || status === "open") {
				clearInterval(durationInt);
				this.countDuration();
			} else if (status === "closed") {
				clearInterval(durationInt);
			}
		}, 1000);

		const statusInt = setInterval(() => {
			const status = this.callInstance?.status();

			if (status === "ringing" || status === "open") {
				if (this.callSid) {
					axios.get(`/voice/v1/calls/status/${this.callSid}`)
						.then(({ data: call }) => {
							if (call?.answeredAt) {
								this.answered(statusInt);
							}
						})
						.catch(err => {
							console.error(err);
							this.answered(statusInt);
						});
				} else {
					this.answered(statusInt);
				}
			} else if (status === "closed") {
				clearInterval(statusInt);
			}
		}, 1000);
	};

	static from(callInstance, parameters) {
		return new OutboundCall(callInstance, parameters);
	}
}

export default OutboundCall;
