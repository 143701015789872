export const ISO_COUNTRY_FR = "FR";
export const REPOSITORY_URL = "https://github.com/plogg-bucco/bucco-app";

export const CONSULTATION = 0;
export const PROTOCOL = 1;
export const KIT = 2;
export const ARTICLE = 3;

export const ALL_DENTISTS = "-1";

export const TEXTO_TAB = 0;
export const EMAILS_TAB = 1;
export const NOTES_TAB = 2;
export const AVAILABLE_COUNTRIES = ["BE", "CA", "FR", "CH", "LU", "NL", "US", "GB", "ES", "AD", "MC", "IT"];

export const ALERT_HIDDEN = 0;
export const ALERT_SHOWN = 1;
export const ALERT_BLOCKED = 2;
export const SINGLE_BLOCK_HEIGHT = 100;
export const SELECT_MAX_HEIGHT = 200;

export const GOOGLE_PROVIDER = "google";
export const OUTLOOK_PROVIDER = "MICROSOFT";
export const EMAIL_PROVIDERS = {
	[GOOGLE_PROVIDER]: "google",
	[OUTLOOK_PROVIDER]: "outlook"
};

export const CONSULTATION_WHITELIST = [
	"19sXkyV5X3H5blTtfRTj",
	"waR8AzOXCCIAprMQJnU1",
	"dTquVrHDuuHO12mV1pQ0",
	"t1nn2HfT3442uOT30KK1",
	"gtMY94MBsuj02oqiTGVO"
];

export const DEFAULT_DATE = "1970-01-01";

export const IMAGE_EXTENSIONS = [".jpg", ".jpeg", ".png"];

export const DIALOG_MAX_WIDTH = "600";
export const GROUP_DIALOG_WIDTH = "75%";

export const MAX_SIZE_ATTACHMENT_SIZE_IN_BYTE = 20000000;
export const MAX_IMAGE_SIZE = 2000000;
export const MAX_PDF_SIZE = 600000;
export const MAX_NOTE_LENGTH = 10000;
export const MAX_UPLOAD_SIZE = 25000000;

export const SMS_SEGMENT_LENGTH = 160;

export const DEFAULT_WEEK_SCHEDULE = [
	{
		day: 0,
		enabled: false,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 1,
		enabled: true,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 2,
		enabled: true,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 3,
		enabled: true,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 4,
		enabled: true,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 5,
		enabled: true,
		end: "17:00",
		start: "9:00"
	},
	{
		day: 6,
		enabled: false,
		end: "17:00",
		start: "9:00"
	}
];

export const DEFAULT_WORKDAY_SCHEDULE = [
	{
		day: 0,
		enabled: false,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 1,
		enabled: true,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 2,
		enabled: true,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 3,
		enabled: true,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 4,
		enabled: true,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 5,
		enabled: true,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	},
	{
		day: 6,
		enabled: false,
		workingHours: [
			{
				start: "09:00",
				end: "17:00"
			}
		]
	}
];

export const WEEK_DAYS = 7;
export const DAY_HOURS = 24;

export const MORNING_START = "03:59";
export const MORNING_END = "12:00";
export const AFTERNOON_START = "11:59";
export const AFTERNOON_END = "17:00";
export const FORMAT = "HH:mm";

export const EMAIL_STATUS = {
	SENDING: "sending",
	SENT: "sent"
};

export const MAX_ACTIVE_ALERTS = 3;

export const PHONE_CALL_STATUS = {
	NO_ANSWER: "no-answer",
	BUSY : "busy",
	CANCELED: "canceled",
	COMPLETED: "completed"
};

export const MESSAGE_MAX_CHARACTER_COUNT = 600;
export const LOCALE_FR = "fr-FR";
export const MAX_CONTACT_PAGES = 10;

export const USER_NAME_MAX_LENGTH = 50;
export const CONTACT_NAME_MAX_LENGTH = 50;

export const AUTHENTICATION_WINDOW_NAME = "fresk_authentication_window";

export const PROVIDER_NAME_MAP = {
	google: "Gmail",
	outlook: "Outlook"
};

export const CALL_STATUS = {
	PENDING: "pending",
	CONNECTING: "connecting",
	RINGING: "ringing",
	OPEN: "open",
	CLOSED: "closed",
	ACCEPT: "accept"
};

export const DEFAULT_LOCALE = "fr-CA";

export const LANGUAGE = {
	"fr-CA": "Français (Canada)",
	"en-CA": "English (Canada)"
};

export const ROLES = {
	ADMIN: "admin",
	SUPER_ADMIN: "super-admin",
	USER: "user",
	DEVOPS: "devops",
	MARKETING: "marketing"
};

export const SIGNATURE_OPTION = {
	NO_SIGNATURE: "no-signature",
	CLINIC: "clinic",
	USER: "user"
};

export const PHONE_MESSAGE_STATUS = {
	COMPLETED: "completed",
	QUEUED: "queued",
	RINGING: "ringing",
	IN_PROGRESS: "in-progress",
	ANSWERED: "answered",
	BUSY: "busy",
	FAILED: "failed",
	NO_ANSWER: "no-answer",
	CANCELLED: "canceled",
	CALLBACK_REQUEST: "callback-request"
};

export const MessageType = {
	MEDIA: "media",
	TEXT: "text"
};

export const MediaContentType = {
	AUDIO: "audio",
	IMAGE: "image",
	JPEG: "jpeg",
	PDF: "pdf",
	PNG: "png"
};

export const MAX_EMAIL_LENGTH = 2500;
export const ANONYMOUS_CALLERS = ["+266696687", "Anonymous"];
