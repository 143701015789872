import EMOJIS from "./emojis";
import Vue from "vue";
import { MessagingEvent } from "../composables/messaging/messaging-event";
import axios from "axios";
import moment from "moment";
import messaging from "../composables/messaging";
import useConversation, { getConversation } from "@/composables/conversation";
import store from "../store";
import { ROLES } from "@/common/const";
import DOMPurify from "dompurify";

const conversationsHook = useConversation();

export function devMode() {
	return ["development"].includes(process.env.NODE_ENV);
}

export function prodMode() {
	return ["production"].includes(process.env.NODE_ENV);
}

/**
 * Checks if the phone number is either an open phone or a twilio number in the database.
 * @param {*} phoneNumber - The phone number to verify.
 * @returns {boolean} - True if the phone number is an open phone or twilio, false otherwise.
 */
export async function isOpenPhoneOrTwilio(phoneNumber) {
	const { data: allOrganizations } = await axios.get("organizations");

	// Might be a little hard on the BE. Ideally have an endpoint with all twilio numbers.
	const phonesObj = await Promise.all(
		allOrganizations.map(async (org) => {
			const { data: data } = await axios.get(`organizations/${org.id}/phone-numbers`);

			return data;
		})
	);

	const phones = phonesObj.flat(Infinity).map((phone) => {
		return phone.phoneNumber.replace(/\D/g, "");
	});

	phones.push(...[process.env.VUE_APP_OPEN_PHONE_1, process.env.VUE_APP_OPEN_PHONE_2]);
	phoneNumber = phoneNumber.replace(/\D/g, "");
	return phones.includes(phoneNumber);
};

export function getSortedPosition(array, conversation) {
	let low = 0;

	let high = array.length;

	while (low < high) {
		const mid = Math.floor((low + high) / 2);

		if (conversation.dateUpdated > getConversation(array[mid])?.dateUpdated) {
			high = mid;
		} else {
			low = mid + 1;
		}
	}

	return low;
}

export function insertSorted(array, conversation) {
	const pos = getSortedPosition(array, conversation);

	array.splice(pos, 0, sid);
}

// Formats phone number to (XXX) XXX-XXXX
export function phoneNumberFormater(phoneNumber) {
	if (!phoneNumber) return "";
	const cleaned = `${phoneNumber.replace(/\D/g, "")}`;
	const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

	return match ? `(${match[2]}) ${match[3]}-${match[4]}` : phoneNumber;
};

// Get line type from a patient record
// export function getLineType(patient) {
// 	let result = null;

// 	try {
// 		const lookup = JSON.parse(patient.lookup);

// 		result = lookup?.lineTypeIntelligence?.type;
// 	} catch (err) {}

// 	try {
// 		const insight = JSON.parse(patient.insight);

// 		result = insight?.attributes?.connection || result;
// 	} catch (err) {}

// 	return result;
// }

export function formatToE164(phoneNumber) {
	const numericOnly = phoneNumber.replace(/\D/g, "");
	const defaultCountryCode = "+1";
	const cleanNumber = numericOnly.startsWith("0") ? numericOnly.slice(1) : numericOnly;
	const formattedNumber = cleanNumber.startsWith("+") ? cleanNumber : defaultCountryCode + cleanNumber;

	return formattedNumber;
};

export function initialsGenerator(fullName) {
	return fullName.split(" ").filter(w => w).map(w => w[0]).join("").slice(0, 2);
};

export function initialLastNameGenerator(fullName) {
	if (!fullName) return "";

	fullName = fullName.replace(/\s+/g, " ").trim();

	const names = fullName.split(" ");

	const firstName = names[0];

	const lastNameInitial = names.length > 1 ? names[1][0] : "";

	return firstName + " " + lastNameInitial.toUpperCase();
}

export function fullName(name) {
	return `${firstName(name)} ${lastName(name)}`.trim();
}

export function firstName(name) {
	return name?.first || "";
}

export function lastName(name) {
	return name?.last || "";
}

export async function fetchUsers() {
	const { data: users } = await axios.get("users");

	return users
		.map((user) => {
			return {
				identity: user.id,
				fullName: fullName(user.name),
				phoneNumber: user.settings ? user.settings.phoneNumber : "1111111111"
			};
		})
		.sort((userA, userB) => userA.fullName - userB.fullName);
};

export function convertTextToEmoji(text) {
	let textArr = text.split(" ");

	Object.entries(EMOJIS).map(([text, emoji]) => {
		textArr.map((newText, index) => {
			if (newText === text) {
				textArr[index] = emoji;
			}
		});
	});

	return textArr.join(" ");
};

export async function createConversation(url, options) {
	if (store.getters["clinic/isFrance"]) {
		const thread = await messaging.threadList.create(options.to, options.body, options.attributes);

		new MessagingEvent().onThreadCreated(thread);

		return {
			data: thread
		};
	}

	return await axios.post(url, options);
};

export async function updateConversationStateAttributes(id, options) {
	if (store.getters["clinic/isFrance"]) {
		const { state, ...rest } = options; // FIXME: use a better impl to update thread status

		const { data: updatedThread } = await messaging.threadList.update(id, {
			...rest,
			status: state
		});

		new MessagingEvent().onThreadUpdated(updatedThread);

		return {
			data: updatedThread
		};
	}

	return await axios.put(`conversations/${id}`, options);
};

export async function deleteConversation(id) {
	if (store.getters["clinic/isFrance"]) {
		return await messaging.threadList.remove(id);
	}

	return await axios.delete(`conversations/${id}`);
};

export async function shortenURL(url) {
	const { data: tinyUrl } = await axios.post("link/create", { url });

	return tinyUrl;
};

export function validatePhoneForE164(phone) {
	const regEx = /^\+[1-9]\d{10,14}$/;

	return regEx.test(phone);
};

export function birthdayFormater(birthday) {
	if (birthday) {
		return moment.utc(birthday).format("DD / MM / YYYY");
	} else {
		return "";
	}
}

export function birthdayPickerFormater(birthday) {
	if (birthday) {
		return moment.utc(birthday).format("YYYY-MM-DD");
	} else {
		return "";
	}
}

// Force download files
// downloadCallback() will receive both the href and the extension of the file to be downloaded
export async function download(url, downloadCallback) {
	const { data: fileBlob } = await axios.create().get(url, {
		responseType: "blob",
		withCredentials: false
	});

	const href = window.URL.createObjectURL(fileBlob);
	const extension = fileBlob.type.split("/")[1];

	try {
		await downloadCallback(href, extension);
	} catch (err) {
		throw err;
	} finally {
		window.URL.revokeObjectURL(url);
	}
};

export async function reloadConversation(currentGroup) {
	try {
		if (store.getters["clinic/isFrance"]) {
			await messaging.bootstrap();
		} else {
			console.debug("RELOAD USER GROUP:", currentGroup);
			await conversationsHook.bootstrap(currentGroup);
		}
	} catch (error) {
		console.error(error);
	}
};

export function currentLocale(locale) {
	// We only support "fr-CA" & "en-CA" for now
	const convertLocale = {
		"en-CA": "en-CA",
		"en-US": "en-CA",
		"en": "en-CA",
		"fr-CA": "fr-CA",
		"fr-FR": "fr-CA",
		"fr": "fr-CA"
	};

	return convertLocale[locale];
};

// Format time in sec to mm:ss or hh:mm:ss if needed
export function formatTime(time) {
	try {
		require("moment-duration-format");

		const duration = moment.duration(time, "seconds");
		const hours = Math.floor(time / 3600);

		if (hours > 0) {
			return duration.format("HH:mm:ss", { trim: false });
		} else {
			return duration.format("mm:ss", { trim: false });
		}
	} catch (error) {
		console.error(error);
	}
}

export function getBrowserLanguage() {
	const [userLanguage] = navigator.language.split("-");

	return currentLocale(userLanguage);
};

export async function getMicrophoneStatus() {
	try {
		let microphonePermissionGranted = false;

		// Request microphone access directly
		try {
			const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
			// If access is not granted, we open panel for granting access.

			microphonePermissionGranted = true;
			// Stop the audio tracks immediately since we only want to check permission
			stream.getTracks().forEach(track => track.stop());
		} catch (error) {
			// If we get an error, permission is denied
			microphonePermissionGranted = false;
		}
		return microphonePermissionGranted;
	} catch (err) {
		console.error(err);
	}
};

// Sanitize content and format it to include clickable links
export function formatContent(content) {
	const urlRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)/g;

	const sanitizedMessage = DOMPurify.sanitize(content);
	const formattedMessage = sanitizedMessage.replace(urlRegex, (link) => {
		const url = link.startsWith("http")? link : `https://${link}`;

		return `<a href='${url}' target='_blank' rel='noopener noreferrer'>${link}</a>`;
	});

	return formattedMessage;
}

// Open the link on a new tab for external links
export function addTargetBlankToLinks(content) {
	return content.replace(/<a([^>]+)>/gi, "<a target=\"_blank\" rel=\"noopener noreferrer\"$1>");
}

export function setViewVariable(obj, path, value) {
	let current = obj;

	for (let i = 0; i < path.length - 1; i++) {
		if (!current[path[i]]) {
			Vue.set(current, path[i], {});
		}
		current = current[path[i]];
	}
	Vue.set(current, path[path.length - 1], value);
}

export function streamToBuffer(stream) {
	return new Promise((resolve, reject) => {
		const buf = [];

		stream.on("data", (chunk) => buf.push(chunk));
		stream.on("end", () => resolve(Buffer.concat(buf)));
		stream.on("error", (err) => reject(err));
	});
}

export function hasAdminRolePermissions(userRole) {
	switch (userRole) {
		case ROLES.ADMIN:
		case ROLES.DEVOPS:
		case ROLES.SUPER_ADMIN:
			return true;
		default:
			return false;
	}
}

export function sortWorkingHours(workDays) {
	return workDays.map(day => {
		day.workingHours = day.workingHours.sort((a, b)	=> {
			const timeA = new Date(`01/01/2000 ${a.start}:00`).getTime();
			const timeB = new Date(`01/01/2000 ${b.start}:00`).getTime();

			return timeA - timeB;
		});
		return day;
	});
}
