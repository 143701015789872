var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list",
    { attrs: { nav: "" } },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "v-list-item",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: item.disabled ? !item.disabled() : true,
              expression: "item.disabled ? !item.disabled() : true",
            },
          ],
          key: index,
          attrs: { link: "", to: item.route },
          on: {
            click: function ($event) {
              return _vm.$emit(`click:${item.id}`)
            },
          },
        },
        [
          _c(
            "v-list-item-icon",
            { staticClass: "mr-3" },
            [
              _c(
                "v-badge",
                {
                  attrs: {
                    value: !index && _vm.notification,
                    color: "red",
                    overlap: "",
                    dot: "",
                  },
                },
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(" " + _vm._s(item.icon) + " "),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-list-item-content",
            { staticClass: "ml-2" },
            [
              _c("v-list-item-title", [
                _vm._v(
                  " " + _vm._s(_vm.$t(item.title, item.titleParams)) + " "
                ),
              ]),
              item.subtitle
                ? _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(item.subtitle) + " "),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }